<template>
    <section class="position-relative about">
        <background v-bind:images="bgImages"></background>
        <div class="container text-white pb-5">
            <div class="about-title-filter pb-4"
                 data-aos="fade-down"
                 data-aos-duration="800"
                 data-aos-delay="300">
                <svg class="w-100 h-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 684 95.429">
                    <path class="cls-1" d="M124.4277,404.9025v64.83q0,12.61,14.4251,12.61v1.7939H81.2059v-1.7939q14.425,0,14.4117-12.61v-64.83q0-12.61-14.4117-12.6126v-1.8018h36.0226c1.0962,0,2.37.9394,3.8771,2.7942,1.4861,1.8708,2.3722,2.7863,2.6,2.7863q9.7281-6.4722,18.7453-6.48,28.2687,0,39.6236,12.2436a46.1029,46.1029,0,0,1,6.4854-5.7637q9.6963-6.4722,18.7238-6.48,46.81,0,46.81,33.3238v46.82q0,12.61,14.4038,12.61v1.7939H210.8826v-1.7939c9.6165,0,14.393-4.2031,14.393-12.61V424.7116q0-33.304-17.9915-33.3159-9.3776,0-18.3735,6.663a26.3965,26.3965,0,0,0-5.5727,5.4056q5.9232,7.5548,5.9335,19.4482v46.82q0,12.61,14.4039,12.61v1.7939H146.05v-1.7939q14.3853,0,14.4039-12.61V424.7116q0-33.304-18.0021-33.3159-9.3621,0-18.3844,6.663A61.1774,61.1774,0,0,1,124.4277,404.9025Z" transform="translate(-81.2059 -389.5887)"></path>
                    <path class="cls-1" d="M387.4034,484.1367h-35.67c-1.1861,0-2.5-.9554-3.9246-2.85-1.4331-1.9-2.2611-2.8607-2.5157-2.8607q-9.0754,6.6-22.0992,6.5916-22.6725,0-36.3121-10.1581-13.6609-10.158-13.65-22.8288,0-12.666,13.5544-22.3914,13.5415-9.72,36.4078-9.72a53.4744,53.4744,0,0,1,21.3987,4.458v-5.3417q0-27.6678-23.1873-27.664a56.7082,56.7082,0,0,0-28.3566,7.6718l-1.088-1.6057a60.3876,60.3876,0,0,1,29.4446-7.8491q51.71,0,51.7189,47.2793v32.9975c0,8.3272,4.7765,12.4879,14.2791,12.4879ZM344.5927,426.333a51.0707,51.0707,0,0,0-21.3987-4.6305q-13.2233,0-18.56,9.991-2.858,4.8161-2.85,20.3373,0,18.9066,4.6279,24.6122c3.6833,4.4156,9.2771,6.6022,16.7815,6.6022q13.5532,0,21.7568-6.772-.3582-11.0658-.3581-21.2317Z" transform="translate(-81.2059 -389.5887)"></path>
                    <path class="cls-1" d="M494.0679,407.6038a14.0573,14.0573,0,0,1-4.15,10.0866,13.6292,13.6292,0,0,1-10.2534,4.3145,14.0632,14.0632,0,0,1-10.0836-4.1395,13.6113,13.6113,0,0,1-4.3309-10.2616l.7112-7.2044q0-7.2128-6.8357-7.2046-12.212,0-22.6832,11.8854v64.6527q0,12.61,14.3933,12.61v1.7939H393.22v-1.7939q14.393,0,14.4038-12.61v-64.83q0-12.61-14.4038-12.6126v-1.8018h36.0253q1.6,0,3.2268,2.89a25.8024,25.8024,0,0,1,2.8872,5.7558l1.0825,3.0621a55.4194,55.4194,0,0,1,12.61-9.192q7.1966-3.4112,20.1566-3.4151A32.3079,32.3079,0,0,1,486.51,394.27C491.5311,397.3982,494.0679,401.8323,494.0679,407.6038Z" transform="translate(-81.2059 -389.5887)"></path>
                    <path class="cls-1" d="M498.25,390.4881h57.7959V392.29q-14.457,0-14.4677,12.6471v64.7535q0,12.6338,14.4677,12.6523v1.7939H498.25v-1.7939q14.4252,0,14.4463-12.6523V404.937q0-12.6456-14.4463-12.6471Z" transform="translate(-81.2059 -389.5887)"></path>
                    <path class="cls-1" d="M610.2112,485.0177q-24.1213,0-37.1188-12.4137-12.8487-12.4227-12.8436-35.2955,0-22.6806,13.0134-35.2852,13.04-12.4308,36.949-12.4346,23.8863,0,36.9091,12.4346,13.0238,12.5983,13.0237,35.2852H588.78q0,31.8828,3.5665,36.92a21.0166,21.0166,0,0,0,17.8643,9.0065,50.3164,50.3164,0,0,0,25.1374-6.6606A52.8432,52.8432,0,0,0,654.0833,459.1l1.4222.9022a53.1466,53.1466,0,0,1-19.2544,18.1746A52.0338,52.0338,0,0,1,610.2112,485.0177ZM588.78,435.5094h42.8214q0-27.3615-2.6641-33.1359c-3.3569-7.3157-9.5662-10.9778-18.7265-10.9778-9.0649,0-14.8816,3.0569-17.5141,9.1787Q588.7965,410.1235,588.78,435.5094Z" transform="translate(-81.2059 -389.5887)"></path>
                    <path class="cls-1" d="M765.2059,407.6038a14.1456,14.1456,0,0,1-4.15,10.0866,13.6532,13.6532,0,0,1-10.2643,4.3145,14.0573,14.0573,0,0,1-10.073-4.1395,13.5919,13.5919,0,0,1-4.3309-10.2616l.722-7.2044q0-7.2128-6.8571-7.2046-12.2278,0-22.6829,11.8854v64.6527q0,12.61,14.4145,12.61v1.7939H664.358v-1.7939q14.3934,0,14.3932-12.61v-64.83q0-12.61-14.3932-12.6126v-1.8018h36.0041c1.0723,0,2.1655.9633,3.2586,2.89a25.6386,25.6386,0,0,1,2.866,5.7558l1.0828,3.0621a56.0853,56.0853,0,0,1,12.5993-9.192q7.2283-3.4112,20.1887-3.4151A32.1963,32.1963,0,0,1,757.627,394.27Q765.2059,398.9625,765.2059,407.6038Z" transform="translate(-81.2059 -389.5887)"></path>
                </svg>
            </div>
            <view-title color="transparent" text-color="white" reveal-animation="fade-in"
                        v-bind:title="$t('message.about.title')">
            </view-title>
            <h5 class="text-left font-weight-normal mb-2"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="300">
                {{ $t("message.about.tag-line-lead") }}
            </h5>
            <h3 class="text-center font-weight-light mb-5"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="300">
                {{ $t("message.about.tag-line") }}
            </h3>
            <p class="text-justify mb-4"
               data-aos="fade-in"
               data-aos-duration="800"
               data-aos-delay="300">
                {{ $t("message.about.paragraph-1") }}
            </p>
            <p class="text-justify mb-4"
               data-aos="fade-in"
               data-aos-duration="800"
               data-aos-delay="300">
                {{ $t("message.about.paragraph-2") }}
            </p>
            <hr>
            <h4 class="text-center mb-4"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="300">
                {{ $t("message.about.pharmicell-title") }}
            </h4>
            <p class="text-justify mb-4"
               data-aos="fade-in"
               data-aos-duration="800"
               data-aos-delay="300">
                {{ $t("message.about.paragraph-3") }}
            </p>
            <p class="text-justify mb-4"
               data-aos="fade-in"
               data-aos-duration="800"
               data-aos-delay="300">
                {{ $t("message.about.paragraph-4") }}
            </p>
            <p class="text-justify mb-4"
               data-aos="fade-in"
               data-aos-duration="800"
               data-aos-delay="300">
                {{ $t("message.about.paragraph-5") }}
            </p>
            <hr>
            <p class="text-justify mb-4"
               data-aos="fade-in"
               data-aos-duration="800"
               data-aos-delay="300">
                {{ $t("message.about.paragraph-6") }}
            </p>
            <p class="text-justify mb-5 pb-5"
               data-aos="fade-in"
               data-aos-duration="800"
               data-aos-delay="300">
                {{ $t("message.about.paragraph-7") }}
            </p>
            <div class="mb-5">
                <b-embed type="iframe"
                         aspect="16by9"
                         src="https://www.youtube.com/embed/2htTGzpDTUw"
                         allowfullscreen>
                </b-embed>
            </div>
        </div>
    </section>
</template>

<script>
    import {
        BEmbed
    } from "bootstrap-vue";

    import ViewTitle from "../components/ViewTitle";
    import Background from "../components/Background";
    export default {
        name: "About",
        components: {
            Background,
            ViewTitle,
            BEmbed,
        },
        metaInfo () {
            return {
                title: this.$t("message.about.title"),
                meta: [{
                    "name": "description",
                    "content": `${ this.$t("message.about.paragraph-1") } ${ this.$t("message.about.paragraph-2") }`,
                    "vmid": "description",
                }, {
                    "property": "og:title",
                    "content": this.$t("message.about.title"),
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }, {
                    "property": "og:description",
                    "content": `${ this.$t("message.about.paragraph-1") } ${ this.$t("message.about.paragraph-2") }`,
                    "vmid": "og:description"
                }, {
                    "property": "og:type",
                    "content": "profile",
                    "vmid": "og:type"
                }, {
                    "property": "fb:app_id",
                    "content": "148635335285458",
                    "vmid": "fb:app_id"
                }, /*{
                    "property": "og:image",
                    "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                    "vmid": "og:image"
                }*/]
            }
        },
        data () {
            return {
                bgImages: {
                    "image/jp2": "./about_bg.jp2",
                    "image/webp": "./about_bg.webp",
                    "default": "./about_bg.png"
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .about {
        .about-title-filter {
            padding-top: 62px;

            & > svg {
                & > .cls-1 {
                    fill: rgba(0, 0, 0, 0.35);
                }
            }
        }

        hr {
            border-top: 1px solid rgba(255, 255, 255, 1);
            margin-bottom: 3rem;
            margin-top: 3rem;
        }
    }
</style>
